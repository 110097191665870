import React from "react";
import { useAccessToken } from "./hooks/useAuthentication";
import Header from "./components/Header";
import ExcelUploader from "./components/ExcelUploader";

const App: React.FC = () => {
  const { accounts } = useAccessToken();

  return (
    <div className="App">
      <Header />
      <div>
        {accounts[0] ? <p>You are logged in</p> : <p>You are not logged in</p>}
      </div>
      {accounts[0] ? <ExcelUploader /> : <p></p>}
    </div>
  );
};

export default App;
