import { useState, useEffect, ChangeEvent } from "react";
import * as XLSX from "xlsx";
import { backendUrl } from "../constants";

const ExcelUploader = () => {
  const [sheets, setSheets] = useState<string[]>([]);
  const [selectedSheets, setSelectedSheets] = useState<string[]>([]);
  const [fileName, setFileName] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [uploadStatus, setUploadStatus] = useState<
    "success" | "failure" | null
  >(null);
  const [message, setMessage] = useState<string>("");
  const [progress, setProgress] = useState<number>(0);
  const [taskId, setTaskId] = useState<string | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files?.[0];
    if (uploadedFile) {
      setFileName(uploadedFile.name);
      setFile(uploadedFile);

      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        setSheets(workbook.SheetNames);
      };

      reader.readAsArrayBuffer(uploadedFile);
    }
  };

  const handleSubmit = async () => {
    if (!file || selectedSheets.length === 0) {
      setMessage("Please select a file and at least one sheet.");
      setUploadStatus("failure");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("sheets", JSON.stringify(selectedSheets));

    try {
      const response = await fetch(`${backendUrl}/upload_excel`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        setTaskId(result.task_id);
        setShowModal(true);
        setMessage("Upload in progress...");
        setUploadStatus(null);
      } else {
        setMessage("Upload failed. Please try again.");
        setUploadStatus("failure");
      }
    } catch (error) {
      setMessage("An error occurred. Please try again.");
      setUploadStatus("failure");
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (!taskId) return;

    const eventSource = new EventSource(`${backendUrl}/progress/${taskId}`);
    eventSource.onmessage = (event) => {
      const progressData = Number(event.data);
      setProgress(progressData);

      if (progressData === 100) {
        eventSource.close();
        setUploadStatus("success");
        setMessage("Upload successful!");
        setShowModal(false);
      }
    };

    eventSource.onerror = () => {
      setMessage("An error occurred while tracking progress.");
      setUploadStatus("failure");
      eventSource.close();
    };

    return () => eventSource.close();
  }, [taskId]);

  return (
    <div>
      <h2>Upload Excel File</h2>
      <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
      <p>File: {fileName}</p>

      {sheets.length > 0 && (
        <div>
          <h3>Select Sheets</h3>
          {sheets.map((sheet, index) => (
            <div key={index}>
              <input
                type="checkbox"
                value={sheet}
                onChange={(e) => {
                  const { checked, value } = e.target;
                  setSelectedSheets((prev) =>
                    checked ? [...prev, value] : prev.filter((s) => s !== value)
                  );
                }}
              />
              {sheet}
            </div>
          ))}
          <button onClick={handleSubmit}>Submit</button>
        </div>
      )}

      {/* Display Yellow Modal during submission */}
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: "20%",
            left: "50%",
            transform: "translate(-50%, -20%)",
            backgroundColor: "#fdfd96",
            padding: "20px",
            borderRadius: "5px",
            zIndex: 1000,
          }}
        >
          <h4>Uploading...</h4>
          <p>File: {fileName}</p>
          <p>Sheets: {selectedSheets.join(", ")}</p>
          <p>Progress: {progress}%</p>
        </div>
      )}

      {/* Success Message Modal */}
      {uploadStatus === "success" && (
        <div
          style={{
            position: "fixed",
            top: "20%",
            left: "50%",
            transform: "translate(-50%, -20%)",
            backgroundColor: "#d4edda",
            padding: "20px",
            borderRadius: "5px",
            zIndex: 1000,
          }}
        >
          <h4>Upload Complete</h4>
          <p>{message}</p>
        </div>
      )}

      {uploadStatus === "failure" && (
        <div style={{ color: "red" }}>{message}</div>
      )}
    </div>
  );
};

export default ExcelUploader;
